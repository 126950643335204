import React, { useState } from 'react'
import { Button, Card, Col, Form, Modal, Row } from 'react-bootstrap'

const Courses = () => {
    const [show, setShow] = useState(false);
    const courses = [
        {
            src: "/assets/slider1.webp",
            courseName: "First Class",
            courseDesc: "Separated they live in. A small river named Duden flows by their place and supplies it with the necessary regelialia. It is a paradisematic country"
        },
        {
            src: "/assets/slider2.webp",
            courseName: "Second Class",
            courseDesc: "Separated they live in. A small river named Duden flows by their place and supplies it with the necessary regelialia. It is a paradisematic country"
        },
        {
            src: "/assets/slider1.webp",
            courseName: "Third Class",
            courseDesc: "Separated they live in. A small river named Duden flows by their place and supplies it with the necessary regelialia. It is a paradisematic country"
        },
        {
            src: "/assets/slider2.webp",
            courseName: "Fourth Class",
            courseDesc: "Separated they live in. A small river named Duden flows by their place and supplies it with the necessary regelialia. It is a paradisematic country"
        },
    ]
    const [formValues, setFormValues] = useState([{ title: "", description: "", lectureNo: "", lectureTitle: "", lectureDuration: "" }])

    let handleChange = (i, e) => {
        let newFormValues = [...formValues];
        newFormValues[i][e.target.name] = e.target.value;
        setFormValues(newFormValues);
    }

    let addFormFields = () => {
        setFormValues([...formValues, { title: "", description: "", lectureNo: "", lectureTitle: "", lectureDuration: "" }])
    }

    let removeFormFields = (i) => {
        let newFormValues = [...formValues];
        newFormValues.splice(i, 1);
        setFormValues(newFormValues)
    }
    return (
        <React.Fragment>
            <div className='text-dark'>
                <Button variant='primary' className='mb-3' onClick={() => setShow(true)}>Add Course</Button>
                <Row className='mb-5 mt-2'>
                    {courses.map((item, index) =>
                        <Col key={index} lg={3} md={4} sm={6} className="mt-4">
                            <Card>
                                <Card.Img className='courseImg mouse' variant="top" src={item.src} />
                                <Card.Body className='mouse' >
                                    <h4 className='mutedClr fw-bold'>{item.courseName}</h4>
                                    <Card.Text className='courseDesc'>
                                        {item.courseDesc}
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                    )}
                </Row>
            </div>
            <Modal show={show} onHide={() => { setShow(false) }} size="xl" centered>
                <Modal.Header closeButton >
                    <Modal.Title>Add Course</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Form.Group as={Col} className='mb-3' controlId="validationCustom03">
                            <Form.Label>Course Name</Form.Label>
                            <Form.Control required type="text" placeholder="Course Name" />
                        </Form.Group>
                        <Form.Group as={Col} className='mb-3' controlId="validationCustom03">
                            <Form.Label>Duration</Form.Label>
                            <Form.Control required type="text" placeholder="Enter Duration" />
                        </Form.Group>
                    </Row>
                    <Row>
                        <Form.Group as={Col} xs="6" className='mb-3' controlId="validationCustom03">
                            <Form.Label>Course Fee</Form.Label>
                            <Form.Control required type="text" placeholder="Enter Fee" />
                        </Form.Group>
                        <Form.Group as={Col} xs="6" className='mb-3' controlId="validationCustom03">
                            <Form.Label>Upload Image</Form.Label>
                            <Form.Control required type="file" />
                        </Form.Group>
                        <Form.Group as={Col} xs="6" className='mb-3' controlId="validationCustom03">
                            <Form.Label>Instructor</Form.Label>
                            <Form.Select >
                                <option>Select Instructor</option>
                            </Form.Select>
                        </Form.Group>
                        <Form.Group as={Col} xs="6" className='mb-3' controlId="validationCustom03">
                            <Form.Label>Demo Link</Form.Label>
                            <Form.Control required type="text" placeholder="Enter Demo Link" />
                        </Form.Group>
                    </Row>
                    <Form.Group className='mb-3' controlId="validationCustom03">
                        <Form.Label>Description</Form.Label>
                        <Form.Control required as="textarea" rows={6} placeholder="Enter Description" />
                    </Form.Group>
                    <Form.Label>Syllabus</Form.Label>
                    <Row className='border mx-0 py-3'>
                        {formValues.map((item, index) =>
                            <Form.Group key={index} as={Col} xs="6" className='mb-3' controlId="validationCustom03">
                                <div className='border p-2'>
                                    <Form.Control
                                        required
                                        type="text"
                                        placeholder="Enter Title"
                                        className='mb-3'
                                        value={item.title}
                                        onChange={(e) => handleChange(index, e)}
                                    />
                                    <Form.Control
                                        required
                                        type="text"
                                        placeholder="Enter Description"
                                        className='mb-3'
                                        value={item.description}
                                        onChange={(e) => handleChange(index, e)}
                                    />
                                    <Form.Control
                                        required
                                        type="text"
                                        placeholder="Enter Lecture No"
                                        className='mb-3'
                                        value={item.lectureNo}
                                        onChange={(e) => handleChange(index, e)}
                                    />
                                    <Form.Control
                                        required
                                        type="text"
                                        placeholder="Enter Lecture Title"
                                        className='mb-3'
                                        value={item.lectureTitle}
                                        onChange={(e) => handleChange(index, e)}
                                    />
                                    <Form.Control
                                        required
                                        type="text"
                                        placeholder="Enter Lecture Duration"
                                        className='mb-3'
                                        value={item.lectureDuration}
                                        onChange={(e) => handleChange(index, e)}
                                    />
                                    <div className='text-center'>
                                        <Button variant="danger"  onClick={() => removeFormFields(index)}>Remove</Button>
                                    </div>
                                </div>
                            </Form.Group>
                        )}
                        <Col xs="12 text-center">
                            <Button className='px-5' variant="warning" type="submit" onClick={() => addFormFields()}>Add</Button>
                        </Col>
                    </Row>

                    <div className='text-center mt-4'>
                        <Button className='px-5' variant="primary" type="submit">Submit</Button>
                    </div>
                </Modal.Body>
            </Modal>
        </React.Fragment>
    )
}

export default Courses