import React, { useEffect, useState } from 'react'
import { Button, Col, Form, Modal, Row } from 'react-bootstrap'

const About = () => {
    const [show, setShow] = useState(false);
    const [data, setData] = useState([]);
    const [aboutData, setAboutData] = useState({
        src: "",
        title: "",
        description: "",
        status: "",
    })

    const [validated, setValidated] = useState(false);

    const handleSubmit = (event) => {
        event.preventDefault();
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        } else {
            handleAddBanner()
        }

        setValidated(true);
    };

    const getAboutData = () => {
        var formdata = new FormData();

        var requestOptions = {
            method: 'POST',
            body: formdata,
            redirect: 'follow'
        };

        fetch("http://localhost:5050/school/abouts", requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result.status === "success") {
                    setData(result.data)
                }
            })
            .catch(error => console.log('error', error));
    }
    useEffect(() => {getAboutData()}, [])

    const handleAddBanner = (event) => {
        var formdata = new FormData();
        formdata.append("title", aboutData.title);
        formdata.append("image", aboutData.src);
        formdata.append("description", aboutData.description);
        formdata.append("status", aboutData.status);

        var requestOptions = {
            method: 'POST',
            body: formdata,
            redirect: 'follow'
        };

        fetch("http://localhost:5050/school/addAbout", requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result.status === "success") {
                    alert(result.msg)
                    setShow(false)
                } else {
                    alert(result.msg)
                }
            })
            .catch(error => console.log('error', error));
    }
    return (
        <React.Fragment>
            <Button variant='primary' className='mb-3' onClick={() => setShow(true)}>Add About</Button>
            <Row className='mb-4'>
                {data && data.map((item, index) =>
                    <Col md={6} className="mt-4" key={index}>
                        <div className='border p-2'>
                            <Form.Group controlId="validationCustom03">

                                <div className='position-relative'>
                                    <span className='isPhotoX' >&#10006;</span>
                                    <img src={process.env.REACT_APP_S3URL + item.image} alt="" className='isPhoto' />
                                </div>
                            </Form.Group>
                            <Form.Group controlId="validationCustom03" className='my-3'>
                                <Form.Label>Title</Form.Label>
                                <Form.Control
                                    required
                                    type="text"
                                    placeholder="Enter Title"
                                    name='title'
                                    value={item.title}
                                />
                            </Form.Group>
                            <Form.Group controlId="validationCustom03">
                                <Form.Label>Description</Form.Label>
                                <Form.Control
                                    required
                                    as="textarea"
                                    rows={5}
                                    placeholder="Enter Description"
                                    name='description'
                                    value={item.description}
                                />
                            </Form.Group>
                            <Form.Group className='my-3 d-flex' controlId="validationCustom04">
                                <div>Status: </div>
                                <Form.Check
                                    required
                                    label={item.status}
                                    name='status'
                                    className="mx-3"
                                    type="radio"
                                    checked
                                />
                            </Form.Group>

                            <div className='text-center mt-3'>
                                <button className='btn btn-danger' >Remove</button>
                            </div>
                        </div>
                    </Col>
                )}

            </Row>

            <Modal show={show} onHide={() => { setShow(false) }} centered size='lg'>
                <Modal.Header closeButton >
                    <Modal.Title>Add About</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form noValidate validated={validated} onSubmit={handleSubmit}>

                        <Form.Group controlId="validationCustom03">
                            {!aboutData.src &&
                                <label className={` regPhoto`}>
                                    Upload Image
                                    <Form.Control
                                        required
                                        type="file"
                                        className='d-none'
                                        onChange={(e) => {
                                            setAboutData({ ...aboutData, src: e.target.files[0] })
                                        }}
                                    />
                                </label>
                            }
                            {aboutData.src &&
                                <div className='position-relative'>
                                    <span className='isPhotoX' onClick={() =>
                                        setAboutData({ ...aboutData, src: "" })} >&#10006;</span>
                                    <img src={URL.createObjectURL(aboutData.src)} alt="" className='isPhoto' />
                                </div>}
                        </Form.Group>
                        <Form.Group controlId="validationCustom03" className='my-3'>
                            <Form.Label>Title</Form.Label>
                            <Form.Control
                                required
                                type="text"
                                placeholder="Enter Title"
                                name='title'
                                value={aboutData.title}
                                onChange={(e) => setAboutData({ ...aboutData, title: e.target.value })}
                            />
                        </Form.Group>

                        <Form.Group controlId="validationCustom03">
                            <Form.Label>Description</Form.Label>
                            <Form.Control
                                required
                                as="textarea"
                                rows={5}
                                placeholder="Enter Description"
                                name='description'
                                value={aboutData.description}
                                onChange={(e) => setAboutData({ ...aboutData, description: e.target.value })}
                            />
                        </Form.Group>

                        <Form.Group className='my-3 d-flex' controlId="validationCustom04">
                            <div>Status: </div>
                            <Form.Check
                                required
                                value="Active"
                                label="Active"
                                name='status'
                                className="mx-3"
                                type="radio"
                                onChange={(e) => setAboutData({ ...aboutData, status: e.target.value })}
                            />

                            <Form.Check
                                required
                                value="Disabled"
                                label="Disabled"
                                name='status'
                                type='radio'
                                onChange={(e) => setAboutData({ ...aboutData, status: e.target.value })}
                            />
                        </Form.Group>

                        <div className='text-center mt-3'>
                            <button className='btn btn-danger' onClick={() => { }}>Add</button>
                        </div>
                    </Form>
                </Modal.Body>
            </Modal>
        </React.Fragment>
    )
}

export default About