import React from 'react'
import { Col, Form, Row, Table } from 'react-bootstrap'

const Inquaries = () => {
    return (
        <React.Fragment>
            <div className='text-center my-3'>Inquaries</div>
            <div className=''>
                <Row>
                    <Col xs="4">
                        <Form.Group>
                            <Form.Control
                                type='search'
                                placeholder='Search'
                            />
                        </Form.Group>
                    </Col>
                    <Col xs="4">
                        <Form.Group>
                            <Form.Control
                                type='date'
                            />
                        </Form.Group>
                    </Col>
                    <Col xs="4 d-flex align-items-center">
                        <span>Sort By</span>
                        <Form.Group className='w-75 ms-3'>
                            <Form.Select className='w-100'>
                                <option>Select</option>
                            </Form.Select>
                        </Form.Group>
                    </Col>
                </Row>
                <Table bordered className='border-white text-white my-4'>
                    <thead>
                        <tr>
                            <th>Sl. No.</th>
                            <th>Name</th>
                            <th>Email</th>
                            <th>Mobile No</th>
                            <th>Message</th>
                            <th>Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>1</td>
                            <td>Pradeep Kumar</td>
                            <td>1st</td>
                            <td>1234567890</td>
                            <td>New</td>
                            <td>
                                <label>
                                <input type="checkbox" />
                                Marks Done
                                </label>
                            </td>
                        </tr>
                    </tbody>
                </Table>
            </div>
        </React.Fragment>
    )
}

export default Inquaries