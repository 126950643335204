import React, { useState } from 'react'
import { Button, Col, Form, Modal, Row } from 'react-bootstrap'

const Banner = () => {
    const [show, setShow] = useState(false);
    const [bannerData, setBannerData] = useState({
        src: "",
        heading: "",
        subHeading: ""
    })

    return (
        <React.Fragment>
            <Button variant='primary' className='mb-3' onClick={() => setShow(true)}>Add Banner</Button>
            <Row className='mb-4'>
                <Col md={6} className="mt-4">
                    <div className='border p-2'>
                        <Form.Group controlId="validationCustom03">

                            <div className='position-relative'>
                                <span className='isPhotoX' >&#10006;</span>
                                <img src={"/logo512.png"} alt="" className='isPhoto' />
                            </div>
                        </Form.Group>
                        <Form.Group controlId="validationCustom03" className='my-3'>
                            <Form.Label>Heading</Form.Label>
                            <Form.Control
                                required
                                type="text"
                                placeholder="Enter Heading"
                                name='heading'
                            />
                        </Form.Group>
                        <Form.Group controlId="validationCustom03">
                            <Form.Label>Sub Heading</Form.Label>
                            <Form.Control
                                required
                                as="textarea"
                                rows="5"
                                placeholder="Enter Sub Heading"
                                name='subHeading'
                            />
                        </Form.Group>
                        <div className='text-center mt-3'>
                            <button className='btn btn-danger' >Remove</button>
                        </div>
                    </div>
                </Col>
            </Row>

            <Modal show={show} onHide={() => { setShow(false) }} centered>
                <Modal.Header closeButton >
                    <Modal.Title>Add Banner</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group controlId="validationCustom03">
                        {!bannerData.src &&
                            <label className={` regPhoto`}>
                                Upload Banner Image
                                <Form.Control
                                    required
                                    type="file"
                                    className='d-none'
                                    onChange={(e) => {
                                        setBannerData({ ...bannerData, src: e.target.files[0] })
                                    }}
                                />
                            </label>
                        }
                        {bannerData.src &&
                            <div className='position-relative'>
                                <span className='isPhotoX' onClick={() =>
                                    setBannerData({ ...bannerData, src: "" })} >&#10006;</span>
                                <img src={URL.createObjectURL(bannerData.src)} alt="" className='isPhoto' />
                            </div>}
                    </Form.Group>
                    <Form.Group controlId="validationCustom03" className='my-3'>
                        <Form.Label>Heading</Form.Label>
                        <Form.Control
                            required
                            type="text"
                            placeholder="Enter Heading"
                            name='heading'
                            value={bannerData.heading}
                            onChange={(e) => setBannerData({ ...bannerData, heading: e.target.value })}
                        />
                    </Form.Group>
                    <Form.Group controlId="validationCustom03">
                        <Form.Label>Sub Heading</Form.Label>
                        <Form.Control
                            required
                            type="text"
                            placeholder="Enter Sub Heading"
                            name='subHeading'
                            value={bannerData.subHeading}
                            onChange={(e) => setBannerData({ ...bannerData, subHeading: e.target.value })}
                        />
                    </Form.Group>
                    <div className='text-center mt-3'>
                        <button className='btn btn-danger' onClick={() => { }}>Add</button>
                    </div>
                </Modal.Body>
            </Modal>
        </React.Fragment>
    )
}

export default Banner