import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { FaMicrosoft } from 'react-icons/fa'
import Dashboard from '../pages/Dashboard';
import { Col, Container, Nav, Navbar, NavDropdown, Row } from 'react-bootstrap';
import About from '../pages/About';
import Banner from '../pages/Banner';
import Courses from '../pages/Courses';
import Events from '../pages/Events';
import News from '../pages/News';
import Inquaries from '../pages/Inquaries';
import ParentReview from '../pages/ParentReview';
import RegistrationList from '../pages/RegistrationList';
import Staff from '../pages/Staff';
import BecomeTeachersList from '../pages/BecomeTeachersList';

const SideNav = ({setUsername}) => {
    let { pathname } = useLocation();
    const navigate = useNavigate();

    return (
        <React.Fragment>
            <Container fluid className='bg_dark'>
                <Row>
                    <Col xs="1 p-0 lightbg overflow-scroll fs14">
                        <div onClick={() => navigate("/")} className={pathname === "/" ? "leftActive mt-1" : 'leftItem mt-1'}>
                            <FaMicrosoft /><br />
                            Dashboard
                        </div>
                        <div onClick={() => navigate("/about")} className={pathname === "/about" ? "leftActive" : 'leftItem'}>
                            <FaMicrosoft /><br />
                            About
                        </div>
                        <div onClick={() => navigate("/banner")} className={pathname === "/banner" ? "leftActive" : 'leftItem'}>
                            <FaMicrosoft /><br />
                            Banner
                        </div>
                        <div onClick={() => navigate("/courses")} className={pathname === "/courses" ? "leftActive" : 'leftItem'}>
                            <FaMicrosoft /><br />
                            Courses
                        </div>
                        <div onClick={() => navigate("/events")} className={pathname === "/events" ? "leftActive" : 'leftItem'}>
                            <FaMicrosoft /><br />
                            Events
                        </div>
                        <div onClick={() => navigate("/news")} className={pathname === "/news" ? "leftActive" : 'leftItem'}>
                            <FaMicrosoft /><br />
                            News
                        </div>
                        <div onClick={() => navigate("/parentReview")} className={pathname === "/parentReview" ? "leftActive" : 'leftItem'}>
                            <FaMicrosoft /><br />
                            ParentReview
                        </div>
                        <div onClick={() => navigate("/staff")} className={pathname === "/staff" ? "leftActive" : 'leftItem'}>
                            <FaMicrosoft /><br />
                            Staff
                        </div>
                        <div onClick={() => navigate("/registrationList")} className={pathname === "/registrationList" ? "leftActive" : 'leftItem'}>
                            <FaMicrosoft /><br />
                            RegistrationList
                        </div>
                        <div onClick={() => navigate("/becomeTeachersList")} className={pathname === "/becomeTeachersList" ? "leftActive" : 'leftItem'}>
                            <FaMicrosoft /><br />
                            Become Teachers
                        </div>
                        <div onClick={() => navigate("/inquaries")} className={pathname === "/inquaries" ? "leftActive" : 'leftItem'}>
                            <FaMicrosoft /><br />
                            Inquaries
                        </div>
                    </Col>
                    <Col xs="11 bg_dark overflow-scroll">
                        <Header setUsername={setUsername}/>
                        {pathname === "/dashboard" && <Dashboard />}
                        {pathname === "/about" && <About />}
                        {pathname === "/banner" && <Banner />}
                        {pathname === "/courses" && <Courses />}
                        {pathname === "/events" && <Events />}
                        {pathname === "/news" && <News />}
                        {pathname === "/inquaries" && <Inquaries />}
                        {pathname === "/parentReview" && <ParentReview />}
                        {pathname === "/staff" && <Staff />}
                        {pathname === "/registrationList" && <RegistrationList />}
                        {pathname === "/becomeTeachersList" && <BecomeTeachersList />}
                    </Col>
                </Row>
            </Container>
        </React.Fragment>
    )
}

export default SideNav

export const Header = ({setUsername}) => {
    const navigate= useNavigate();
    const handleSignout = ()=>{
        sessionStorage.clear()
        navigate("/")
        setUsername("")
    }
    return (
        <React.Fragment>
            <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark" className='mb-3 headerMN12'>
                <Container>
                    <Navbar.Brand >Achiever's Dashboard</Navbar.Brand>
                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                    <Navbar.Collapse id="responsive-navbar-nav">
                        <Nav className="me-auto">
                            {/* <Nav.Link >Features</Nav.Link>
                            <Nav.Link >Pricing</Nav.Link> */}
                        </Nav>
                        <Nav>
                            {/* <Nav.Link href="#deets">More deets</Nav.Link>
                            <Nav.Link eventKey={2} href="#memes">
                                Dank memes
                            </Nav.Link> */}
                            <NavDropdown title="Admin" id="collasible-nav-dropdown" className='headerSignout headerDropdown'>
                                <NavDropdown.Item onClick={()=> handleSignout()}>Logout</NavDropdown.Item>
                            </NavDropdown>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </React.Fragment>
    )
}
