import React, { useState } from 'react'
import { Button, Form, Modal } from 'react-bootstrap'

const News = () => {
    const [show, setShow] = useState(false);
    const latestNews = [
        {
            src: "/assets/news1.jpeg",
            title: "Summer Course Starts From 1st June",
            date: "March 14, 2022",
            description: "Dimply dummy text of the printing and typesetting industry. Lorem Ipsum has been"
        },
        {
            src: "/assets/news1.jpeg",
            title: "Summer Course Starts From 1st June",
            date: "March 14, 2022",
            description: "Dimply dummy text of the printing and typesetting industry. Lorem Ipsum has been printing and typesetting industry. Lorem Ipsum has been"
        },
        {
            src: "/assets/news1.jpeg",
            title: "Summer Course Starts From 1st June",
            date: "March 14, 2022",
            description: "Dimply dummy text of the printing and typesetting industry. Lorem Ipsum has been printing and typesetting industry. Lorem Ipsum has been"
        },
    ]
    return (
        <React.Fragment>
            <div className='text-dark'>
                <Button variant='primary' className='mb-3' onClick={() => setShow(true)}>Add News</Button>
                {latestNews.map((item, index) =>
                    <div className='d-flex boxShadow p-2 mt-3 mouseOver' key={index}>
                        <img src={item.src} className="newImg" alt={item.title} />
                        <div className='ms-3'>
                            <h5>{item.title}</h5>
                            <h6 className='orangeClr'>{item.date}</h6>
                            <p className='mb-0 line_clamp text-secondary'>{item.description}</p>
                        </div>
                    </div>
                )}
            </div>

            <Modal show={show} onHide={() => { setShow(false) }} centered>
                <Modal.Header closeButton >
                    <Modal.Title>Add News</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group className='mb-3' controlId="validationCustom03">
                        <Form.Label>Title</Form.Label>
                        <Form.Control required type="text" placeholder="Enter Title" />
                    </Form.Group>
                    <Form.Group className='mb-3' controlId="validationCustom03">
                        <Form.Label>Date</Form.Label>
                        <Form.Control required type="text" placeholder="Enter Date" />
                    </Form.Group>
                    <Form.Group className='mb-3' controlId="validationCustom03">
                        <Form.Label>Description</Form.Label>
                        <Form.Control required type="text" placeholder="Enter Description" />
                    </Form.Group>
                    <Form.Group className='mb-3' controlId="validationCustom03">
                        <Form.Label>Upload Image</Form.Label>
                        <Form.Control required type="file" />
                    </Form.Group>
                    <div className='text-center'>
                        <Button className='px-5' variant="primary" type="submit">Add</Button>
                    </div>
                </Modal.Body>
            </Modal>
        </React.Fragment>
    )
}

export default News