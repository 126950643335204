import React, { useState } from 'react'
import { Button, Col, Form, Modal, Row } from 'react-bootstrap'

const Events = () => {
    const [show, setShow] = useState(false);
    const events = [
        {
            title: "SAT Implementation Workshops November 2026",
            date: "May 2022",
            time: "11:00 - 13:30",
            description: "Bimply dummy text of the printing and typesetting istryrem Ipsum has been the industry’s standard dummy text ever since the 1500s, when an unknown printer.when an unknown printer took a galley of type and scrambled"
        },
        {
            title: "SAT Implementation Workshops November 2026",
            date: "May 2022",
            time: "11:00 - 13:30",
            description: "Bimply dummy text of the printing and typesetting istryrem Ipsum has been the industry’s standard dummy text ever since the 1500s, when an unknown printer.when an unknown printer took a galley of type and scrambled"
        },
    ]
    return (
        <React.Fragment>
            <div className='text-dark'>
                <Button variant='primary' className='mb-3' onClick={() => setShow(true)}>Add Event</Button>
                {events.map((item, index) =>
                    <div className='d-flex boxShadow p-2 my-3 mouseOver' key={index}>
                        <div className="rtin-calender">
                            <h3>20</h3>
                            <p>Oct</p>
                            <div className='eventYear'>2026</div>
                        </div>
                        <div className='ms-3'>
                            <h5>{item.title}</h5>
                            <p className='mb-0 line_clamp4 text-secondary mt-3 mb-3'>{item.description}</p>
                            <div className='blueClr mt-2 fw-bold'>{item.time}</div>
                        </div>
                    </div>
                )}
            </div>

            <Modal show={show} onHide={() => { setShow(false) }} centered>
                <Modal.Header closeButton >
                    <Modal.Title>Add Event</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group className='mb-3' controlId="validationCustom03">
                        <Form.Label>Title</Form.Label>
                        <Form.Control required type="text" placeholder="Enter Title" />
                    </Form.Group>
                    <Row>
                        <Form.Group as={Col} className='mb-3' controlId="validationCustom03">
                            <Form.Label>Start Date</Form.Label>
                            <Form.Control required type="text" placeholder="Enter Date" />
                        </Form.Group>
                        <Form.Group as={Col} className='mb-3' controlId="validationCustom03">
                            <Form.Label>Start Time</Form.Label>
                            <Form.Control required type="text" placeholder="Enter Start Time" />
                        </Form.Group>
                    </Row>
                    <Row>
                        <Form.Group as={Col} className='mb-3' controlId="validationCustom03">
                            <Form.Label>End Date</Form.Label>
                            <Form.Control required type="text" placeholder="Enter Date" />
                        </Form.Group>
                        <Form.Group as={Col} className='mb-3' controlId="validationCustom03">
                            <Form.Label>End Time</Form.Label>
                            <Form.Control required type="text" placeholder="Enter Start Time" />
                        </Form.Group>
                    </Row>
                    <Form.Group className='mb-3' controlId="validationCustom03">
                        <Form.Label>Description</Form.Label>
                        <Form.Control required as="textarea" rows={3} placeholder="Enter Description" />
                    </Form.Group>
                    <Form.Group className='mb-3' controlId="validationCustom03">
                        <Form.Label>Upload Image</Form.Label>
                        <Form.Control required type="file" />
                    </Form.Group>
                    <div className='text-center'>
                        <Button className='px-5' variant="primary" type="submit">Add</Button>
                    </div>
                </Modal.Body>
            </Modal>
        </React.Fragment>
    )
}

export default Events