import React from 'react'
import { Col, Form, Row, Table } from 'react-bootstrap'

const BecomeTeachersList = () => {
    return (
        <React.Fragment>
            <div className='text-center my-3'>Become Teachers</div>
                <Row>
                    <Col xs="4">
                        <Form.Group>
                            <Form.Control
                                type='search'
                                placeholder='Search'
                            />
                        </Form.Group>
                    </Col>
                    <Col xs="4">
                        <Form.Group>
                            <Form.Control
                                type='date'
                            />
                        </Form.Group>
                    </Col>
                    <Col xs="4 d-flex align-items-center">
                        <span>Sort By</span>
                        <Form.Group className='w-75 ms-3'>
                            <Form.Select className='w-100'>
                                <option>Select</option>
                            </Form.Select>
                        </Form.Group>
                    </Col>
                </Row>
                <Table bordered className='border-white text-white my-4'>
                    <thead>
                        <tr>
                            <th>Sl. No.</th>
                            <th>Name</th>
                            <th>Designation</th>
                            <th>Qualification</th>
                            <th>Mobile No</th>
                            <th>Status</th>
                            <th>Resume</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>1</td>
                            <td>Pradeep Kumar</td>
                            <td>Teacher</td>
                            <td>B.Pharma</td>
                            <td>1234567890</td>
                            <td>New</td>
                            <td>Download</td>
                        </tr>
                    </tbody>
                </Table>
        </React.Fragment>
    )
}

export default BecomeTeachersList