import React, { useState } from 'react'
import { Button, Form, Modal } from 'react-bootstrap'
import { FaEye, FaEyeSlash } from 'react-icons/fa'
import { useNavigate } from 'react-router-dom'

const Home = ({ setUsername }) => {
    const navigate = useNavigate()
    const [show, setShow] = useState(false)
    const [showHide, setShowHide] = useState(false)

    const [login, setLogin] = useState({
        username: '',
        password: ''
    })

    const [validated, setValidated] = useState(false);

    const handleSubmit = (event) => {
        event.preventDefault();
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        } else {
            handleLogin()
        }

        setValidated(true);
    };

    const handleLogin = (event) => {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
            "username": login.username,
            "password": login.password
        });

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch("http://localhost:5050/school/loginUser", requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result.status === "success") {
                    sessionStorage.setItem("username", result.data.username)
                    navigate("/")
                    setUsername(result.data.username)
                } else {
                    alert(result.msg)
                }
            })
            .catch(error => console.log('error', error));
    }

    return (
        <React.Fragment>
            <div className='mainHome' >
                <h1>404</h1>
                <div className="cloak__wrapper">
                    <div className="cloak__container">
                        <div className="cloak"></div>
                    </div>
                </div>
                <div className="info">
                    <h2>We can't find that page</h2>
                    <p>We're fairly sure that page used to be here, but seems to have gone missing. We do apologise on it's behalf.</p>

                </div>
                <div className='position-absolute bottom-0 end-0 p-4 text-dark' onClick={() => setShow(true)}> </div>
            </div>
            <Modal show={show} onHide={() => setShow(false)} centered className='bg-transparent'>
                <Modal.Header closeButton>
                    <Modal.Title>Login</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form noValidate validated={validated} onSubmit={handleSubmit}>
                        <Form.Group controlId="formBasicUsername" className='my-3'>
                            <Form.Label>Username</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter username"
                                required
                                value={login.username}
                                onChange={(e) => setLogin({ ...login, username: e.target.value })}
                            />
                        </Form.Group>
                        <Form.Group controlId="formBasicPassword">
                            <Form.Label>Password</Form.Label>
                            <Form.Control
                                type={showHide ? "text" : "password"}
                                placeholder="Password"
                                required
                                value={login.password}
                                onChange={(e) => setLogin({ ...login, password: e.target.value })}
                            />
                            {!showHide ?
                                <FaEye onClick={() => setShowHide(true)} className="showHide" />
                                :
                                <FaEyeSlash onClick={() => setShowHide(false)} className="showHide" />
                            }
                        </Form.Group>
                        <Button variant="primary" type="submit" className='my-3'>
                            Login
                        </Button>
                    </Form>

                </Modal.Body>
            </Modal>
        </React.Fragment>
    )
}

export default Home