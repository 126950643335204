import React from 'react'

const Dashboard = () => {
    return (
        <React.Fragment>
            <div className='bg_dark'>
                Dashboard
            </div>
        </React.Fragment>
    )
}

export default Dashboard