import React from 'react'
import { Col, Form, Row, Table } from 'react-bootstrap'

const RegistrationList = () => {
    return (
        <React.Fragment>
            <div className='text-center my-4'>RegistrationList</div>
                <Row>
                    <Col xs="3">
                        <Form.Group>
                            <Form.Control
                                type='search'
                                placeholder='Search'
                            />
                        </Form.Group>
                    </Col>
                    <Col xs="2">
                        <Form.Group>
                            <Form.Select>
                                <option>Select</option>
                            </Form.Select>
                        </Form.Group>
                    </Col>
                    <Col xs="3">
                        <Form.Group>
                            <Form.Control
                                type='date'
                            />
                        </Form.Group>
                    </Col>
                    <Col xs="4 d-flex align-items-center">
                        <span>Sort By</span>
                        <Form.Group className='w-75 ms-3'>
                            <Form.Select className='w-100'>
                                <option>Select</option>
                            </Form.Select>
                        </Form.Group>
                    </Col>
                </Row>
                <Table bordered className='border-white text-white my-4'>
                    <thead>
                        <tr>
                            <th>Sl. No.</th>
                            <th>Student Name</th>
                            <th>Course Name</th>
                            <th>Contact No</th>
                            <th>Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>1</td>
                            <td>Pradeep Kumar</td>
                            <td>1st</td>
                            <td>1234567890</td>
                            <td>New</td>
                        </tr>
                    </tbody>
                </Table>
        </React.Fragment>
    )
}

export default RegistrationList