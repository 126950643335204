import React, { useEffect, useState } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import Home from './components/Home';
import SideNav from './components/SideNav';

function App() {
  const [username, setUsername] = useState("")
  let isLoggedIn = sessionStorage.getItem("username")
  
  useEffect(() => {
    if (isLoggedIn) {
      setUsername(isLoggedIn)
    }
  }, [isLoggedIn])

  return (
    <React.Fragment>
      <BrowserRouter>
        <Routes>
          {!username ?
            <Route path="/" element={<Home setUsername={setUsername} />} />
            :
            <Route path="/" element={<SideNav setUsername={setUsername} />} />
          }
          <Route path="/about" element={<SideNav />} />
          <Route path="/banner" element={<SideNav />} />
          <Route path="/courses" element={<SideNav />} />
          <Route path="/events" element={<SideNav />} />
          <Route path="/news" element={<SideNav />} />
          <Route path="/inquaries" element={<SideNav />} />
          <Route path="/parentReview" element={<SideNav />} />
          <Route path="/staff" element={<SideNav />} />
          <Route path="/registrationList" element={<SideNav />} />
          <Route path="/becomeTeachersList" element={<SideNav />} />
        </Routes>
      </BrowserRouter>
    </React.Fragment>
  );
}

export default App;
